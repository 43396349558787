import React, { Suspense, lazy } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import zhCN from "antd/locale/zh_CN";
import ErrorBoundary from "./components/ErrorBoundary"; // 导入 ErrorBoundary 组件
import { isMobile } from "./utils/helper";
import { ConfigProvider } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import "./App.scss";

dayjs.locale("zh-cn");

const HelpPage = lazy(() => import("./pages/Help/Help"));

const Stat = lazy(() => import("./pages/Stat/Stat"));
const StatDetail = lazy(() => import("./pages/Stat/Detail"));
const StatLogin = lazy(() => import("./pages/Stat/Login"));

const Report = lazy(() => import("./pages/Report/Report"));

const Complaints = lazy(() => import("./pages/Complaints/Complaints"));
const ComplaintsDetail = lazy(() => import("./pages/Complaints/Detail"));

const ArticleList = lazy(() => import("./pages/Article/ArticleList"));
const ArticlePage = lazy(() => import("./pages/Article/ArticlePage"));

const City = lazy(() => import("./pages/City/City"));

const UserAgreement = lazy(() => import("./pages/UserAgreement/UserAgreement"));
const UserPrivacy = lazy(() => import("./pages/UserPrivacy/UserPrivacy"));
const DownLoad = lazy(() => import("./pages/Download/download"));

const Home = isMobile()
  ? lazy(() => import("./pages/Mobile/Entry"))
  : lazy(() => import("./pages/Entry/Entry"));

const Us = isMobile()
  ? lazy(() => import("./pages/Mobile/AboutUs"))
  : lazy(() => import("./pages/Entry/AboutUs"));

function App() {
  return (
    <ConfigProvider locale={zhCN}>
      <div className="app">
        <HashRouter
          future={{ v7_startTransition: true, v7_relativeSplatPath: true }}
        >
          <ErrorBoundary>
            <Suspense>
              <Routes>
                {/*--------------- 官网 -------------*/}
                <Route path="/" element={<Home />} />
                <Route path="/us" element={<Us />} />
                <Route path="/article/list" element={<ArticleList />} />
                <Route path="/article/:id" element={<ArticlePage />} />
                {/*--------------- 询赋文化 -------------*/}

                <Route path="city" element={<City />} />

                {/*--------------- 举报 webview -------------*/}
                <Route path="report" element={<Report />} />

                {/*--------------- 帮助和反馈 webview -------------*/}
                <Route path="help" element={<HelpPage />} />

                {/*--------------- 用户协议 webview-------------*/}
                <Route path="userAgreement" element={<UserAgreement />} />

                {/*--------------- 隐私政策 webview-------------*/}
                <Route path="userPrivacy" element={<UserPrivacy />} />

                {/*--------------- 下载页 webview-------------*/}
                <Route path="download" element={<DownLoad />} />

                {/*--------------- 申诉 webview -------------*/}
                <Route
                  path="complaints/detail"
                  element={<ComplaintsDetail />}
                />
                <Route path="complaints" element={<Complaints />} />

                {/*--------------- 统计 -------------*/}
                <Route path="stat" element={<Stat />} />
                <Route path="stat/detail" element={<StatDetail />} />
                <Route path="stat/login" element={<StatLogin />} />

                {/*--------------- 其他 -------------*/}
                <Route path="*" element={<div className="dom-404"></div>} />
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </HashRouter>
      </div>
    </ConfigProvider>
  );
}

export default App;
